export const url = [
  { key: '/home', label: 'Home' },
  { key: '/departure-arrival', label: 'Board Departure Arrival' },
  { key: '/maintenance-order', label: 'Board Maintenance Informations' },
  { key: '/periodic-service', label: 'Board Periodic Service' },
  { key: '/delivery-cycle', label: 'Board Delivery Cycle' },
  { key: '/delivery-progress', label: 'Board Delivery Progress' },
  { key: '/attendance', label: 'Board Attendance' },
  { key: '/p2h', label: 'Board P2H' },
  { key: '/medical-record', label: 'Board Medical Record' },
  { key: '/delivery-manpower', label: 'Board Delivery Manpower' },
];
